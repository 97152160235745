import axios, { AxiosRequestConfig } from 'axios';
import { REACT_APP_API } from 'constant/app';

export const instanceAxios = axios.create({
  baseURL: REACT_APP_API,
});

instanceAxios.defaults.headers.common['Content-Type'] = 'application/json';

instanceAxios.interceptors.response.use(
  (response) => {
    if (response.data.code && +response.data.code !== 200) {
      return Promise.reject(response);
    }
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default function request(options: AxiosRequestConfig) {
  return instanceAxios(options);
}
