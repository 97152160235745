/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function DedicatedServerCategory() {
  const navigate = useNavigate();
  return (
    <li className="has-submenu">
      <a href="">Dedicated Server</a>
      <div className="submenu-wrapper menu-list theme-megamenu">
        <div className="row">
          <div className="col-md-6">
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListDedicatedServerEU);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                  <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/european-union.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>Dedicated Châu Âu</h6>
                  </div>
                </div>
              </a>
            </div>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListDedicatedServerUS);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                  <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/united-states.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>Dedicated Mỹ</h6>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
