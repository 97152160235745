/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import URL from "constant/url";
import { useNavigate } from "react-router-dom";


export default function SLA() {
  const navigate = useNavigate();
  return (
    <><section className="breadcrumb-area bg-primary-gradient">
      <div className="container my-container">
        <div className="breadcrumb-content text-center">
          <h2 className="mb-3">Cam kết</h2>
        </div>
      </div>
    </section>
      <section className="vps-feature ptb-60">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="vps-feature-top text-center">
                <h2 className="mb-20">Chất Lượng Dịch Vụ Tốt Nhất</h2>
                <span>Chúng tôi đảm bảo dịch vụ của Quý khách đang sử dụng đạt uptime từ 99,99% trở lên.</span>
              </div>
            </div>
          </div>
          <div className="tab-content mt-5">
            <div
              className="tab-pane fade show active"
              id="vps_feature"
              role="tabpanel"
            >
              <div className="row jusity-content-center g-4">
                <div className="col-lg-6 col-md-6">
                  <div className="vps-feature-item bg-white deep-shadow position-relative zindex-1">
                    <img
                      src="assets/img/shapes/vps-feature-shape.png"
                      alt="not found"
                      className="position-absolute right-bottom"
                    />
                    <div className="figure-icon position-relative zindex-1">
                      <img
                        src="assets/img/vps-hosting/folder.png"
                        alt="ssd"
                        className="img-fluid logo-black"
                      />
                      <img
                        src="assets/img/vps-hosting/ssd-2-white.png"
                        alt="ssd"
                        className="img-fluid logo-white"
                      />
                    </div>
                    <div className="vps-ft-item-content mt-30">
                      <h5>100% Host Node Uptime</h5>
                      <p>
                        Nền tảng phần cứng lưu trữ các phiên bản máy tính của bạn được thiết kế với mục tiêu chính là ổn định. Đồng thời, nhóm kỹ sư của chúng tôi sử dụng giám sát tích cực để chủ động phát hiện các vấn đề và thực hiện các biện pháp phòng ngừa, giảm thiểu bất kỳ tác động nào khi phần cứng máy chủ bị lỗi.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="vps-feature-item bg-white deep-shadow position-relative zindex-1">
                    <img
                      src="assets/img/shapes/vps-feature-shape.png"
                      alt="not found"
                      className="position-absolute right-bottom"
                    />
                    <div className="figure-icon position-relative zindex-1">
                      <img
                        src="assets/img/vps-hosting/speed.png"
                        alt="ssd"
                        className="img-fluid logo-black"
                      />
                      <img
                        src="assets/img/vps-hosting/speed-white.png"
                        alt="ssd"
                        className="img-fluid logo-white"
                      />
                    </div>
                    <div className="vps-ft-item-content mt-30">
                      <h5>100% Network Uptime</h5>
                      <p>
                        Sử dụng kiến trúc dự phòng và định tuyến BGP thông minh, mạng lõi định tuyến một cách thông minh xung quanh hoạt động mạng gián đoạn nhằm nỗ lực cung cấp 100% thời gian hoạt động và kết nối hiệu suất cao trong tất cả các POP trên toàn thế giới của chúng tôi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="vps-tab-info text-center mt-50">
            <p className="mb-0">
              <a href="" onClick={()=> {
                navigate(URL.SLADetail)
              }}>Xem thông tin chi tiết</a>
            </p>
          </div>
        </div>
      </section>
      <section className="cmp-vps-pricing pb-120 ds-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="section-title text-center">
                <h2 className="mb-3">Cam Kết Đền Bù Phí Dịch Vụ</h2>
              </div>
            </div>
          </div>
          <div className="vps-pricing-table mt-5 vps-pricing-table-2">
            <table className="w-100 bg-white deep-shadow">
              <thead>
                <tr>
                  <th>
                    <span className="h6">Thời gian uptime hàng tháng</span>
                  </th>
                  <th>
                    <span className="h6">Thời gian ở trạng thái không sẵn sàng</span>
                  </th>
                  <th>
                    <span className="h6">Mức phí dịch vụ đền bù</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Từ 99,9% tới 100%</td>
                  <td>Tối đa 43 phút</td>
                  <td>0%</td>
                </tr>
                <tr>
                  <td>Từ 99% tới ít hơn 99,9%</td>
                  <td>Tối đa 430 phút</td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td>Từ 90% tới ít hơn 99%</td>
                  <td>Tối đa 4300 phút</td>
                  <td>50%</td>
                </tr>
                <tr>
                  <td>Ít hơn 90%</td>
                  <td>Hơn 4300 phút</td>
                  <td>100%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section></>
  );
}
