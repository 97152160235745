/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function ServerCategory() {
  const navigate = useNavigate();

  return (
    <li className="has-submenu">
      <a href="">VPS</a>
      <div className="submenu-wrapper menu-list theme-megamenu">
        <div className="row">
          <div className="col-md-6">
            <span style={{ color: "black", fontWeight: 600 }}>
              KHU VỰC VIỆT NAM
            </span>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListServerVN);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                  <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/vietnam.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>VPS Việt Nam (VN)</h6>
                  </div>
                </div>
              </a>
            </div>
            <span style={{ color: "black", fontWeight: 600 }}>
              KHU VỰC CHÂU Á
            </span>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListServerSG);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                  <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/singapore.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>VPS Singapore (SG)</h6>
                  </div>
                </div>
              </a>
            </div>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListServerAU);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                  <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/australia.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>VPS Úc (AU)</h6>
                  </div>
                </div>
              </a>
            </div>
            <span style={{ color: "black", fontWeight: 600 }}>
              THEO MỤC ĐÍCH
            </span>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListServerEU);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                  <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/european-union.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>VPS Châu Âu (PTU)</h6>
                  </div>
                </div>
              </a>
            </div>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListVPSGPU);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                  <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/gpu.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>VPS GPU</h6>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <span style={{ color: "black", fontWeight: 600 }}>
              KHU VỰC CHÂU ÂU
            </span>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListServerUK);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/united-kingdom.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>VPS Anh (UK)</h6>
                  </div>
                </div>
              </a>
            </div>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListServerDE);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/germany.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>VPS Đức (DE)</h6>
                  </div>
                </div>
              </a>
            </div>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListServerFR);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/france.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>VPS Pháp (FR)</h6>
                  </div>
                </div>
              </a>
            </div>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListServerNL);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/netherlands.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>VPS Hà Lan (NL)</h6>
                  </div>
                </div>
              </a>
            </div>
            <span style={{ color: "black", fontWeight: 600 }}>
              KHU VỰC ÂU MỸ
            </span>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListServerUS);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/united-states.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>VPS Mỹ (US)</h6>
                  </div>
                </div>
              </a>
            </div>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListServerCA);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/canada.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>VPS Canada (CA)</h6>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
