import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import serverDetail from "../components/ListServerDetail/slice";
import proxyDetail from "../components/ListProxyDetail/slice";
import featurePlan from "../components/FeaturePlan/slice";
import dedicatedServerDetail from "../components/ListDedicatedServerDetail/slice";

const reducers = {
  serverDetail,
  featurePlan,
  proxyDetail,
  dedicatedServerDetail,
};

const rootReducer = combineReducers(reducers);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
