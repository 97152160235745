/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */

import FeaturePlan from "components/FeaturePlan";
import URL from "constant/url";

export default function Content() {
  return (
    <>
      {/* Hero */}
      <div className="host-web-hero-area pt-200 position-relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="position-relative z-1">
                <h6 className="host-fs-sub-title host-web-color-2 d-inline-flex align-items-center gap-3">
                  <svg
                    width={39}
                    height={35}
                    viewBox="0 0 39 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.72317 30.5929L30.1378 0.999768"
                      stroke="#38eeb0"
                      strokeWidth={3}
                    />
                    <path
                      d="M13.7186 32.3431L26.1997 16.7394"
                      stroke="#38eeb0"
                      strokeWidth={3}
                    />
                  </svg>
                  Dịch vụ
                </h6>
                <h1 className="text-white fs-72">
                  Máy chủ ảo <br /> chuyên nghiệp
                </h1>
                <p className="text-white">Đa dạng, tự động, giá hợp lý</p>
                <div className="hero-btns d-flex align-items-center flex-wrap gap-5 mt-5">
                  <a
                    href={URL.BuyProduct}
                    className="btn isb-gd-btn text-white rounded-3 fw-bolder border-0 position-relative z-1"
                  >
                    Trải nghiệm ngay
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="host-web-hero-overlay">
                <img
                  src="assets/img/home_18/musk-logo.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeaturePlan />

      <div className="host-web-info-card-area ptb-60 overflow-hidden">
        <div className="container">
          <div className="position-relative z-1">
            <img
              src="assets/img/home_18/shape/card.png"
              alt=""
              className="shape-1 position-absolute img-fluid z--1"
            />
            <div className="row align-items-center justify-content-between">
              <div className="col-xxl-5 col-xl-6">
                <h2 className="host-web-title text-black fs-48 fw-800">
                  Vì sao nên chọn{" "}
                  <span className="host-web-gd-text">TIMECLOUD</span>
                </h2>
                <p className="mb-30">
                  Với kinh nghiệm nhiều năm tham gia thị trường tại VN, chúng
                  tôi tự tin là đối tác có thể đem đến cho bạn dịch vụ chuyên
                  nghiệp với giá cả hợp lý nhất.
                </p>
              </div>
              <div className="col-xxl-6 col-xl-6">
                <div className="row">
                  <div className="col-md-6">
                    <div className="host-web-card-item bg-white p-4 host-web-shadow rounded-3 mt-30">
                      <img
                        src="assets/img/home_18/card-1.png"
                        alt=""
                        className="img-fluid mb-20"
                      />
                      <h4 className="text-black fs-22 mb-20">VPS Đa Dạng</h4>
                      <p className="mb-0">
                        Đa dạng quốc gia, giá cả phù hợp với cấu hình, hỗ trợ
                        cài đặt Windows và Linux Free
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="host-web-card-item bg-white p-4 host-web-shadow rounded-3 mt-30">
                      <img
                        src="assets/img/home_18/card-1.png"
                        alt=""
                        className="img-fluid mb-20"
                      />
                      <h4 className="text-black fs-22 mb-20">
                        SSD Chuyên Dụng
                      </h4>
                      <p className="mb-0">
                        Sử dụng những phần cứng bền bỉ và ổ SSD để mang lại trải
                        nghiệm tốt nhất cho khách hàng
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="host-web-card-item bg-white p-4 host-web-shadow rounded-3 mt-30">
                      <img
                        src="assets/img/home_18/card-1.png"
                        alt=""
                        className="img-fluid mb-20"
                      />
                      <h4 className="text-black fs-22 mb-20">Hỗ Trợ 24/7</h4>
                      <p className="mb-0">
                        Đội ngũ IT, Chăm sóc khách hàng chuyên nghiệp, sẵn sàng
                        cho mọi tình huống, hỗ trợ nhanh chóng
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="host-web-card-item bg-white p-4 host-web-shadow rounded-3 mt-30">
                      <img
                        src="assets/img/home_18/card-1.png"
                        alt=""
                        className="img-fluid mb-20"
                      />
                      <h4 className="text-black fs-22 mb-20">
                        Dễ Dàng Cài Đặt
                      </h4>
                      <p className="mb-0">
                        Mọi thao tác cài đặt và quản lý đều tự động và dễ dàng
                        sử dụng trong phần Quản Trị
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="host-web-discount-area ptb-60">
        <div className="container">
          <div className="host-web-discount-wrapper p-4 pt-60 pb-60 rounded-3">
            <div className="row justify-content-center">
              <div className="col-xl-7">
                <div className="text-center">
                  <h6 className="host-fs-sub-title host-web-color-2 d-inline-flex align-items-center gap-3">
                    <svg
                      width={23}
                      height={20}
                      viewBox="0 0 23 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.14155 16.6077L18.1894 0.746819"
                        stroke="#38EEB0"
                        strokeWidth="1.79988"
                      />
                      <path
                        d="M8.33836 17.5457L15.8265 9.18274"
                        stroke="#38EEB0"
                        strokeWidth="1.79988"
                      />
                    </svg>
                    VPS, Proxy, Dedicated Server
                  </h6>
                  <h2 className="host-web-title text-white fs-48 fw-800 mb-30">
                    Trải nghiệm ngay hôm nay để hưởng ưu đãi tốt nhất
                  </h2>
                  <a
                    href={URL.BuyProduct}
                    className="btn isb-gd-btn text-white rounded-3 fw-bolder border-0 position-relative z-1"
                  >
                    Mua ngay
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="host-web-faq-work-area pt-60">
        <div className="container">
          <div className="row gap-4 gap-xl-0">
            <div className="col-xl-6">
              <h2 className="host-web-title text-black fs-48 fw-800 mb-30">
                <span className="host-web-gd-text">TIMECLOUD</span> Best
                Services
              </h2>
              <div className="dm-faq-left host-web-work">
                <div className="accordion dm-accordion" id="dm_accordion">
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <a
                        href="#dm_item_one"
                        data-bs-toggle="collapse"
                        aria-expanded="true"
                        className=""
                      >
                        Tại sao nên mua VPS tại TIMECLOUD?
                      </a>
                    </div>
                    <div
                      className="accordion-collapse collapse show"
                      id="dm_item_one"
                      data-bs-parent="#dm_accordion"
                      style={{}}
                    >
                      <div className="accordion-body">
                        <p className="mb-0">
                          Chúng tôi trang bị VPS với phần cứng mạnh mẽ, ổ SSD
                          NVME mang đến sự mượt mà khi sử dụng.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <a
                        href="#dm_item_two"
                        data-bs-toggle="collapse"
                        className="collapsed"
                        aria-expanded="false"
                      >
                        Tôi muốn được support trực tiếp thì liên hệ ở đâu?
                      </a>
                    </div>
                    <div
                      className="accordion-collapse collapse"
                      id="dm_item_two"
                      data-bs-parent="#dm_accordion"
                    >
                      <div className="accordion-body">
                        <p className="mb-0">
                          Bạn có thể liên hệ qua Page{" "}
                          <a
                            href="https://facebook.com/timecloudvn"
                            target="_blank"
                            rel="noreferrer"
                          >
                            www.facebook.com/timecloudvn
                          </a>{" "}
                          hoặc liên hệ trực tiếp admin qua Tele{" "}
                          <a
                            href="https://t.me/VT_VPS"
                            target="_blank"
                            rel="noreferrer"
                          >
                            @timecloudvn
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <a
                        href="#dm_item_three"
                        data-bs-toggle="collapse"
                        className="collapsed"
                        aria-expanded="false"
                      >
                        Phương thức thanh toán như thế nào?
                      </a>
                    </div>
                    <div
                      className="accordion-collapse collapse"
                      id="dm_item_three"
                      data-bs-parent="#dm_accordion"
                    >
                      <div className="accordion-body">
                        <p className="mb-0">
                          Bạn có thể thanh toán thông qua chuyển khoản, momo.
                          Paypal, USDT...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="host-web-faq-right text-center position-relative z-1">
                <img
                  src="assets/img/home_18/work.png"
                  alt=""
                  className="img-fluid"
                />
                <img
                  src="assets/img/home_18/shape/faq-1.png"
                  alt=""
                  className="shape-1 position-absolute img-fluid"
                />
                <img
                  src="assets/img/home_18/shape/faq-2.png"
                  alt=""
                  className="shape-2 position-absolute img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="dtc-partner-section ptb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="dtc-title dtc-title2 text-center mb-5">
                <h6 className="host-fs-sub-title host-web-color-2 d-inline-flex align-items-center gap-3">
                  <svg
                    width={23}
                    height={20}
                    viewBox="0 0 23 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.14155 16.6077L18.1894 0.746819"
                      stroke="#38EEB0"
                      strokeWidth="1.79988"
                    />
                    <path
                      d="M8.33836 17.5457L15.8265 9.18274"
                      stroke="#38EEB0"
                      strokeWidth="1.79988"
                    />
                  </svg>
                  Hệ Điều Hành Hỗ Trợ
                </h6>
                <h3 style={{ fontSize: "16px", color: "white" }}>
                  TIMECLOUD hỗ trợ đầy đủ các hệ điều hành, cài đặt nhanh chóng,
                  thuận tiện chỉ với một click chuột.
                </h3>
              </div>
            </div>
          </div>
          <div className="dtc-partners-list d-flex align-items-center justify-content-center flex-wrap">
            <div className="dtc-partner-single bg-white rounded d-inline-flex align-items-center justify-content-center">
              <img
                src="assets/img/window.png"
                alt="cpanel"
                className="img-fluid"
              />
            </div>
            <div className="dtc-partner-single bg-white rounded d-inline-flex align-items-center justify-content-center">
              <img
                src="assets/img/ubuntu.png"
                alt="cloudlinux"
                className="img-fluid"
              />
            </div>
            <div className="dtc-partner-single bg-white rounded d-inline-flex align-items-center justify-content-center">
              <img
                src="assets/img/centos.png"
                alt="litespeed"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
