/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { actionGetListPlan, selectListPlanDetail } from "./slice";
import { useAppDispatch, useAppSelector } from "store";
import { nanoid } from "@reduxjs/toolkit";
import { MANAGEMENT_URL, SITE_NAME } from "constant/app";

export default function FeaturePlan() {
  const dispatch = useAppDispatch();
  const listPlanDetail = useAppSelector(selectListPlanDetail);

  useEffect(() => {
    dispatch(actionGetListPlan({ featured: true, site_name: SITE_NAME }));
  }, [dispatch]);

  return (
    <section className="pricing-tab-section pt-120 position-relative zindex-1 overflow-hidden mt-0 pricing-details-page">
      <img
        src="assets/img/shapes/line-red-top.png"
        alt="line shape"
        className="position-absolute right-top d-none d-lg-block"
      />
      <img
        src="assets/img/shapes/line-red.svg"
        alt="line shape"
        className="position-absolute left-bottom"
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-heading text-center">
              <h2>Những gói tiêu biểu</h2>
            </div>
          </div>
        </div>
        <div className="pricing-wrapper position-relative zindex-1">
          <img
            src="assets/img/shapes/frame.svg"
            alt="frame"
            className="position-absolute frame-shape"
          />
          <div className="row g-4 justify-content-center mt-4">
            {listPlanDetail.map((item) => (
              <div className="col-lg-4 col-md-6" key={nanoid()}>
                <div className="pricing-column overflow-hidden position-relative bg-white rounded-10 deep-shadow">
                  <h3 className="h3">
                    VPS <br />
                    {item.region}
                  </h3>
                  <span className="popular-badge position-absolute text-center fw-bold">
                    Tiêu biểu
                  </span>
                  <span className="pricing-label d-block mt-4">Giá: </span>
                  <h4 className="h2 mt-2 monthly-price">
                    {item.price}
                    <span>VNĐ/Tháng</span>
                  </h4>
                  <ul className="feature-list mt-2 mb-4" style={{overflow: 'unset', fontSize: '16px'}}>
                    <li>
                      <span className="me-2">
                        <i className="fa-solid fa-rocket" />
                      </span>
                      CPU: {item.cpu}
                    </li>
                    <li>
                      <span className="me-2">
                        <i className="fa-solid fa-database" />
                      </span>
                      RAM: {item.ram}
                    </li>
                    <li>
                      <span className="me-2">
                        <i className="fa-solid fa-globe" />
                      </span>
                      SSD: {item.ssd}
                    </li>
                    <li>
                      <span className="me-2">
                        <i className="fa-solid fa-gears" />
                      </span>
                      IPv4: {item.ipv4}
                    </li>
                    <li>
                      <span className="me-2">
                        <i className="fa-solid fa-briefcase" />
                      </span>
                      Bandwidth: {item.bandwidth}
                    </li>
                    <li>
                      <span className="me-2">
                        <i className="fa-solid fa-network-wired" />
                      </span>
                      Ethernet port: {item.ethernet_port}
                    </li>
                  </ul>
                  <a
                    href={`${MANAGEMENT_URL}/buying?type=VPS&nation=${item.region}`}
                    className="template-btn primary-btn w-100 mt-40 text-center"
                  >
                    Mua ngay
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
