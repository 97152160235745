/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function ProxyCategory() {
  const navigate = useNavigate();
  return (
    <li className="has-submenu">
      <a href="">Proxy</a>
      <div className="submenu-wrapper menu-list theme-megamenu">
        <div className="row">
          <div className="col-md-6">
            <span style={{ color: "black", fontWeight: 600 }}>
              KHU VỰC VIỆT NAM
            </span>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListProxyVN);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                  <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/vietnam.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>Proxy Việt Nam (VN)</h6>
                  </div>
                </div>
              </a>
            </div>
            <span style={{ color: "black", fontWeight: 600 }}>
              KHU VỰC CHÂU Á
            </span>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListProxySG);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                  <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/singapore.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>Proxy Singapore (SG)</h6>
                  </div>
                </div>
              </a>
            </div>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListProxyAU);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                  <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/australia.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>Proxy Úc (AU)</h6>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <span style={{ color: "black", fontWeight: 600 }}>
              KHU VỰC CHÂU ÂU
            </span>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListProxyUK);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/united-kingdom.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>Proxy Anh (UK)</h6>
                  </div>
                </div>
              </a>
            </div>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListProxyDE);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/germany.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>Proxy Đức (DE)</h6>
                  </div>
                </div>
              </a>
            </div>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListProxyFR);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/france.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>Proxy Pháp (FR)</h6>
                  </div>
                </div>
              </a>
            </div>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListProxyNL);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/netherlands.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>Proxy Hà Lan (NL)</h6>
                  </div>
                </div>
              </a>
            </div>
            <span style={{ color: "black", fontWeight: 600 }}>
              KHU VỰC ÂU MỸ
            </span>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListProxyUS);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/united-states.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>Proxy Mỹ (US)</h6>
                  </div>
                </div>
              </a>
            </div>
            <div className="megamenu-item">
              <a
                href=""
                onClick={() => {
                  navigate(URL.ListProxyCA);
                }}
              >
                <div className="menu-list-wrapper d-flex align-items-center">
                <img
                    style={{
                      alignSelf: "center",
                    }}
                    src="assets/flags/canada.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <div className="menu-list-content-right ms-3">
                    <h6>Proxy Canada (CA)</h6>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
