import Footer from "./Footer";
import Header from "./Header";

interface MainLayoutProps {
  children: React.ReactNode;
}

export default function MainLayout(props: MainLayoutProps) {
  const { children } = props;

  return (
      <div className="main_wrapper">
        <Header/>
        {children}
        <Footer />
      </div>
  );
}
