import URL from "constant/url";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Guide() {
  return (
    <>
      <section className="breadcrumb-area bg-primary-gradient">
        <div className="container my-container">
          <div className="breadcrumb-content text-center">
            <h2 className="mb-3">Danh sách hướng dẫn</h2>
          </div>
        </div>
      </section>
      <section className="hm-blog-grids pt-120 pb-120 overflow-hidden">
        <div className="container">
          <div className="row g-5">
            <div className="col-xl-8">
              <div className="hm-blog-grid-left">
                <div className="row g-4">
                  <div className="col-md-12">
                    <div className="hm2-blog-card bg-white deep-shadow">
                      <div className="feature-img rounded-top overflow-hidden">
                        <a href={URL.Guide1}>
                          <img
                            src="assets/img/blog-list-2.jpg"
                            alt="feature"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                      <div className="hm2-blog-card-content position-relative">
                        <a
                          href=""
                          className="tag-btn rounded-pill position-absolute"
                        >
                          Tips
                        </a>
                        <a href={URL.Guide1}>
                          <h3 className="h5 mb-3">
                            Hướng dẫn tăng cường bảo mật VPS
                          </h3>
                        </a>
                        <p>
                          Bạn đang chưa biết có cách nào để tăng cường bảo mật VPS. Hãy đọc bài viết sau để biết thêm chi tiết!
                        </p>
                        <hr className="spacer mt-20 mb-20" />
                        <div className="bog-author d-flex align-items-center justify-content-between">
                          <div className="d-inline-flex align-items-center">
                            <img
                              src="assets/img/home2/client-1.png"
                              alt="author"
                              className="img-fluid rounded-circle"
                            />
                            <h6 className="ms-2 mb-0">Admin</h6>
                          </div>
                          <span className="date">10/11/2022</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="hm2-blog-card bg-white deep-shadow">
                      <div className="feature-img rounded-top overflow-hidden">
                        <a href={URL.Guide2}>
                          <img
                            src="assets/img/blog-list-4.jpg"
                            alt="feature"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                      <div className="hm2-blog-card-content position-relative">
                        <a
                          href=""
                          className="tag-btn rounded-pill position-absolute"
                        >
                          Tips
                        </a>
                        <a href={URL.Guide2}>
                          <h3 className="h5 mb-3">
                            Hướng Dẫn Đổi Password VPS Sử Dụng Windows Server 2012
                          </h3>
                        </a>
                        <p>
                          Có 2 cách thông dụng để đổi mật khẩu VPS WS2012 là bằng dòng lệnh hoặc cài đặt trong User Accounts.
                        </p>
                        <hr className="spacer mt-20 mb-20" />
                        <div className="bog-author d-flex align-items-center justify-content-between">
                          <div className="d-inline-flex align-items-center">
                            <img
                              src="assets/img/home2/client-2.png"
                              alt="author"
                              className="img-fluid rounded-circle"
                            />
                            <h6 className="ms-2 mb-0">Admin</h6>
                          </div>
                          <span className="date">10/11/2022</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="hm2-blog-card bg-white deep-shadow">
                      <div className="feature-img rounded-top overflow-hidden">
                        <a href={URL.Guide3}>
                          <img
                            src="assets/img/blog-list-3.jpg"
                            alt="feature"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                      <div className="hm2-blog-card-content position-relative">
                        <a
                          href=""
                          className="tag-btn rounded-pill position-absolute"
                        >
                          Tips
                        </a>
                        <a href={URL.Guide3}>
                          <h3 className="h5 mb-3">
                            Hướng dẫn mở rộng ổ cứng trên Windows Server và Windows.
                          </h3>
                        </a>
                        <p>
                          Để mở rộng ổ cứng có thể sử dụng powershell hoặc công cụ Disk Management.
                        </p>
                        <hr className="spacer mt-20 mb-20" />
                        <div className="bog-author d-flex align-items-center justify-content-between">
                          <div className="d-inline-flex align-items-center">
                            <img
                              src="assets/img/home2/client-3.png"
                              alt="author"
                              className="img-fluid rounded-circle"
                            />
                            <h6 className="ms-2 mb-0">Admin</h6>
                          </div>
                          <span className="date">10/11/2022</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="hm-blog-sidebar mt-4 mt-xl-0">
                <div className="sidebar-widget categories-widget">
                  <h4 className="widget-title">Danh mục</h4>
                  <ul className="mt-30">
                    <li>
                      <a href="">
                        VPS
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Tips
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Bảo mật
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="sidebar-widget tags-widget">
                  <h4 className="widget-title">Tags.</h4>
                  <div className="tags-list mt-30">
                    <a href="#">Accounts</a>
                    <a href="#">Ổ cứng</a>
                    <a href="#">CMD</a>
                    <a href="#">WS 2012</a>
                  </div>
                </div>
                <div className="sidebar-widget banner-widget">
                  <div className="banner-content bg-primary-gradient rounded-2 text-center position-relative zindex-1 overflow-hidden">
                    <img
                      src="assets/img/shapes/sidebar-bg.png"
                      alt="net"
                      className="position-absolute left-top soft-light w-100 opacity-50"
                    />
                    <h6 className="text-white">Liên hệ</h6>
                    <div className="banner-contact-info mt-30">
                      <span className="icon-wrapper rounded-circle text-white d-inline-flex align-items-center justify-content-center">
                        <i className="fa-solid fa-phone" />
                      </span>
                      <h4>
                        <a
                          href="tel:0962085904"
                          className="text-white mt-20 d-block"
                        >
                          Tel: 0962085904
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
